














































































































































































import CommonUtils from '@/utils/common-utils';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Confirm from './../../components/core/Confirm.vue';
import DateTimeUtils from '@/utils/date-time-utils';
import MessagesMixin from '@/mixins/messages-mixin';
import TableResizeMixin from '@/mixins/table-resize-mixin';
import PageLifeCycleMixin from '@/mixins/page-lifecycle-mixin';
import { tableWidths } from '@/constants/index';

const settings = namespace('settings');
const messages = namespace('messages');

@Component({
  mixins: [PageLifeCycleMixin, MessagesMixin, TableResizeMixin]
})
export default class Messages extends Vue {
  editMode!: boolean;
  searchText!: string;
  selectedRows!: Array<any>;
  hasSelectedRows!: boolean;
  tableHeight!: string;
  localRefreshKey = CommonUtils.generateUUID();
  localMessages: any = [];
  localIsEditingMessagesList = false;
  localIsEditingMessages = false;
  sendQuickReply = false;
  selectedTeacherId = 0;
  headers = [
    { text: this.$t('dateLabel'), value: 'commentDate', width: this.tableWidths.fullDate, sort: DateTimeUtils.compareDates },
    { text: this.$t('nameLabel'), value: 'recipientName', width: this.tableWidths.longText, sortable: true },
    { text: this.$t('messageLabel'), value: 'commentText', sortable: false },
    { value: 'actions', width: this.tableWidths.action3, sortable: false }
  ];

  headersAdmin = [
    { text: this.$t('dateLabel'), value: 'commentDate', width: this.tableWidths.fullDate, sort: DateTimeUtils.compareDates },
    { text: this.$t('fromLabel'), value: 'authorName', width: this.tableWidths.mediumText, sortable: true },
    { text: this.$t('toLabel'), value: 'recipientName', width: this.tableWidths.mediumText, sortable: true },
    { text: this.$t('messageLabel'), value: 'commentText', sortable: false },
    { value: 'actions', width: this.tableWidths.action3, sortable: false }
  ]

  viewAllTeachers = false;

  $refs!: {
    messagesConfirm: Confirm
  }

  @messages.Action
  loadMessages!: (param: any) => Promise<any>;

  @messages.Action
  deleteMessage!: (params?: any) => Promise<any>;

  @messages.Action
  deleteMessages!: (params?: any) => Promise<any>;

  @messages.Action
  loadRecipients!: () => Promise<any>;

  @messages.Getter
  getAllMessages!: any;

  @messages.Getter
  getReplyId!: any;

  @messages.Getter('getListLoading')
  listLoading!: boolean;

  @messages.Getter
  getRecipientsList!: any;

  @messages.Mutation
  setReplyId!: (id: number) => any;

  @settings.Getter('getUserId')
  userId!: any;

  @settings.Getter
  getCurrentTeacherId!: any;

  @settings.Getter('getBrowserHeight')
  browserHeight!: number;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  get tableWidths() {
    return tableWidths;
  }

  get allMessages(): any {
    return this.getAllMessages;
  }

  get viewingUserId() {
    if (this.$currentUser.isAdmin) {
      return this.selectedTeacherId;
    } else {
      return this.userId;
    }
  }

  get isToUser() {
    const that = this;
    return function (message: any) {
      if (that.$currentUser.isStudent) {
        return message.authorType !== 'S'
      } else {
        return message.recipientId === that.viewingUserId
      }
    }
  }

  get canReply() {
    const that = this;
    return function (message: any) {
      if (that.$currentUser.isStudent) {
        return message.authorType !== 'S'
      } else if (that.$currentUser.isAdmin && message.recipientId === 0) {
        return true;
      } else {
        return (message.recipientId === that.userId && that.getRecipientsList.find((r: any) => r.recipientId === message.authorId))
      }
    }
  }

  get enrichedMessage() {
    return this.localMessages.map((m: any, index: number) => {
      m.isSelectable = (this.userId === m.authorId) || (this.$currentUser.isAdmin && m.authorType === 'A');
      m.key = `${m.commentId}-${index}`;
      return m;
    })
  }

  get isNotEmpty() {
    return CommonUtils.isNotEmpty;
  }

  get stringifyDate() {
    return DateTimeUtils.stringifyDate;
  }

  get localListLoading() {
    return this.listLoading;
  }

  set localListLoading(value: boolean) {
    this.$store.commit('messages/setListLoading', value);
  }

  get mobileBreakpoint() {
    return CommonUtils.getMobileBreakpointSize();
  }

  get isMobileMode() {
    return CommonUtils.isMobileMode();
  }

  get mobileTableClass() {
    return CommonUtils.mobileTableClass();
  }

  get mobileRowClass() {
    return CommonUtils.mobileRowClass();
  }

  get mobileHeaderClass() {
    return CommonUtils.mobileHeaderClass();
  }

  get mobileCellClass() {
    return CommonUtils.mobileCellClass();
  }

  get contentBreakpoint() {
    return CommonUtils.mainContentBreakpoint();
  }

  getMessageTitle(message: any) {
    if (this.isToUser(message)) {
      return this.$t('messageFromLabel', { name: message.authorName });
    } else {
      return this.$t('messageToLabel', { name: message.recipientName });
    }
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
    if (this.editMode === false) {
      this.selectedRows = [];
    }
  }

  filterStudentComments(teacherId: any) {
    if (teacherId === 0) {
      this.localMessages = this.getAllMessages;
    } else {
      this.localMessages = this.getAllMessages.filter((m: any) => (m.authorId === teacherId || m.recipientId === teacherId))
    }
  }

  private initialize(initialLoad:boolean) {
    initialLoad ? CommonUtils.showLoading() : this.localListLoading = true;
    this.viewAllTeachers = (this.$currentUser.isAdmin && this.selectedTeacherId === 0);
    this.loadMessages({
      teacherId: this.selectedTeacherId
    }).then(() => {
      this.localMessages = this.allMessages;
    }).finally(() => {
      this.localRefreshKey = CommonUtils.generateUUID();
      CommonUtils.hideLoading();
      this.localListLoading = false;
    });
  }

  private reload() {
    CommonUtils.showLoading();
    this.localMessages = this.allMessages;
    this.localRefreshKey = CommonUtils.generateUUID();
    CommonUtils.hideLoading();
  }

  addMessage() {
    this.$eventBus.$emit('openSubPage', {
      type: 'message',
      width: 600,
      modal: this.$currentUser.defaultEditorMode === 'modal',
      input: {
        action: 'add'
      }
    });
    this.localIsEditingMessages = true;
  }

  addQuickReply(item: any) {
    item.sendQuickReply = true;
    this.$eventBus.$emit('openSubPage', {
      type: 'message',
      width: 600,
      modal: this.$currentUser.defaultEditorMode === 'modal',
      input: {
        action: 'reply',
        recipient: item.authorId,
        messageId: item.commentId,
        schoolId: item.schoolId
      }
    });
    this.localIsEditingMessages = true;
  }

  viewSelectedMessage(item: any) {
    this.$eventBus.$emit('openSubPage', {
      type: 'message',
      width: 600,
      modal: this.$currentUser.defaultEditorMode === 'modal',
      input: {
        action: 'view',
        objectId: item.commentId,
        messageObject: item
      }
    });
    this.localIsEditingMessages = true;
  }

  created() {
    if (+this.getReplyId > 0) {
      CommonUtils.showLoading();
      this.loadRecipients().then(() => {
        this.$eventBus.$emit('openSubPage', {
          type: 'message',
          modal: this.$currentUser.defaultEditorMode === 'modal',
          width: 600,
          input: {
            action: 'reply',
            recipient: +this.getReplyId,
            messageId: +this.getReplyId
          }
        });
        this.setReplyId(0);
      });
    } else {
      this.loadRecipients();
    }
    if (this.$currentUser.isAdmin) {
      this.selectedTeacherId = this.getCurrentTeacherId;
    }
    this.initialize(true);
    this.$nextTick(() => {
      this.$eventBus.$on('closeMessageEditor', (reload: boolean) => {
        this.localIsEditingMessages = false;
        if (reload) {
          this.initialize(false);
        }
      });
      this.$eventBus.$on('userModeChanged', this.onUserModeChanged);
      this.$eventBus.$on('schoolYearChanged', this.onSchoolYearChanged);
      if (this.$currentUser.isAdmin) {
        this.$eventBus.$emit('showAllTeachersOption');
      }
      if (this.$currentUser.isStudent) {
        this.$eventBus.$on('studentTeacherChanged', (value: any) => {
          this.filterStudentComments(value);
        })
      }
      if (!this.$currentUser.isStudent) {
        this.$eventBus.$emit('openDefaultSubPage')
      }
    })
  }

  onUserModeChanged() {
    this.loadRecipients();
    this.initialize(false);
  }

  onSchoolYearChanged(param: any) {
    this.selectedTeacherId = param.teacherId
    this.initialize(false);
  }

  deleteSelectedMessage(message: any) {
    const that = this;
    this.$refs.messagesConfirm.confirm({
      title: this.$t('messageLabel'),
      text: this.$t('deleteMessageLabel'),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result) => {
      if (result === 1) {
        that.localListLoading = true;
        that.deleteMessage({ commentId: message.commentId }).then(() => {
          if (this.showSnackbarNotifications) {
            this.$snotify.success(this.$t('statusMsg63') as string);
          }
          this.$eventBus.$emit('deleteMessage', [message.commentId]);
          CommonUtils.hideLoading();
          that.initialize(false);
        });
      }
    });
  }

  deleteMultipleSelectedMessage() {
    const that = this;
    this.$refs.messagesConfirm.confirm({
      title: this.$t('messageLabel'),
      text: this.$t('confirmDeleteMultipleGeneral', { type: this.$t('messageLabelLowercase') }),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result) => {
      if (result === 1) {
        that.deleteMultiple();
      }
    });
  }

  deleteMultiple() {
    this.localListLoading = true;
    const idList = this.selectedRows.map((e: any) => {
      return e.commentId
    });
    this.deleteMessages({ commentId: idList }).then(() => {
      this.$eventBus.$emit('deleteMessage', idList);
      if (this.showSnackbarNotifications) {
        this.$snotify.success(this.$t('statusMsg62') as string);
      }
      CommonUtils.hideLoading();
      this.initialize(false);
      this.selectedRows = [];
    })
  }

  beforeDestroy() {
    this.$eventBus.$off('closeMessageEditor');
    this.$eventBus.$off('userModeChanged', this.onUserModeChanged);
    this.$eventBus.$off('schoolYearChanged', this.onSchoolYearChanged);
    if (this.$currentUser.isAdmin) {
      this.$eventBus.$emit('hideAllTeachersOption');
    }
    if (this.$currentUser.isStudent) {
      this.$eventBus.$off('studentTeacherChanged')
    }
  }

  getAttachmentName(item: any) {
    return this.getFileName(item.name) + (item.isPrivate ? ' (Private)' : '');
  }

  getFileName(key: string) {
    const name = key.split('/');
    return name[name.length - 1];
  }
}
