var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    {
      ref: "messagesRef",
      staticClass: "messages",
      attrs: { color: "#ffffff" }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "rounded-0",
                  class: { "mt-5": _vm.$vuetify.breakpoint.mdAndUp },
                  attrs: { flat: "" }
                },
                [
                  _c("v-data-table", {
                    ref: "table",
                    attrs: {
                      headers: _vm.viewAllTeachers
                        ? _vm.headersAdmin
                        : _vm.headers,
                      items: _vm.enrichedMessage,
                      "item-key": "key",
                      search: _vm.searchText,
                      "multi-sort": false,
                      "show-select": _vm.editMode,
                      height: _vm.tableHeight,
                      "fixed-header": "",
                      "hide-default-footer": "",
                      "disable-pagination": "",
                      "mobile-breakpoint": _vm.mobileBreakpoint,
                      loading: _vm.localListLoading,
                      "loading-text": _vm.$t("listLoadingMsg")
                    },
                    on: { "click:row": _vm.viewSelectedMessage },
                    scopedSlots: _vm._u([
                      {
                        key: "body",
                        fn: function(ref) {
                          var items = ref.items
                          var isSelected = ref.isSelected
                          var select = ref.select
                          return [
                            _c(
                              "tbody",
                              {
                                ref: "listContainer",
                                staticClass: "list-container"
                              },
                              _vm._l(items, function(item, index) {
                                return _c(
                                  "tr",
                                  {
                                    key: index,
                                    class: _vm.mobileTableClass,
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { tabindex: "0", role: "row" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.viewSelectedMessage(item)
                                      },
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.viewSelectedMessage(item)
                                      }
                                    }
                                  },
                                  [
                                    _vm.editMode
                                      ? _c(
                                          "td",
                                          {
                                            class: _vm.mobileRowClass,
                                            on: {
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                select(item, !isSelected(item))
                                              }
                                            }
                                          },
                                          [
                                            _vm.isMobileMode
                                              ? _c("div", {
                                                  class: _vm.mobileHeaderClass
                                                })
                                              : _vm._e(),
                                            _c("v-checkbox", {
                                              class: _vm.mobileCellClass,
                                              staticStyle: {
                                                margin: "0px",
                                                padding: "0px"
                                              },
                                              attrs: {
                                                disabled: !item.isSelectable,
                                                "input-value": isSelected(item),
                                                color: !_vm.$vuetify.theme.dark
                                                  ? "#7a7a7a"
                                                  : "#ffffff",
                                                "hide-details": "",
                                                "aria-label": _vm.getMessageTitle(
                                                  item
                                                )
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  select(
                                                    item,
                                                    !isSelected(item)
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.headers[0].text)
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { class: _vm.mobileCellClass },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.stringifyDate(
                                                item.commentDate,
                                                true
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm.viewAllTeachers
                                      ? _c(
                                          "td",
                                          { class: _vm.mobileRowClass },
                                          [
                                            _vm.isMobileMode
                                              ? _c(
                                                  "div",
                                                  {
                                                    class: _vm.mobileHeaderClass
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.headersAdmin[1].text
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-flex justify-left",
                                                class: _vm.mobileCellClass
                                              },
                                              [
                                                !_vm.viewAllTeachers
                                                  ? _c("v-icon", {
                                                      staticClass:
                                                        "fa-solid fa-inbox-in",
                                                      attrs: { color: "blue" }
                                                    })
                                                  : _c("v-icon", {
                                                      staticClass:
                                                        "fa-solid fa-inbox-out",
                                                      attrs: { color: "green" }
                                                    }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "padding-left": "10px"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.authorName)
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.viewAllTeachers
                                                    ? _vm.headersAdmin[2].text
                                                    : _vm.headers[1].text
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { class: _vm.mobileCellClass },
                                        [
                                          _vm.viewAllTeachers
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-flex justify-left"
                                                },
                                                [
                                                  _c("v-icon", {
                                                    staticClass:
                                                      "fa-solid fa-inbox-in",
                                                    attrs: { color: "blue" }
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "10px"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.recipientName
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm.isToUser(item)
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-flex justify-left"
                                                },
                                                [
                                                  _c("v-icon", {
                                                    staticClass:
                                                      "fa-solid fa-inbox-in",
                                                    attrs: { color: "blue" }
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "10px"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.authorName)
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-flex justify-left"
                                                },
                                                [
                                                  _c("v-icon", {
                                                    staticClass:
                                                      "fa-solid fa-inbox-out",
                                                    attrs: { color: "green" }
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "10px"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.recipientName
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                        ]
                                      )
                                    ]),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "remove-margin",
                                        class: _vm.mobileRowClass,
                                        style: {
                                          "min-width": _vm.tableWidths.longText
                                        }
                                      },
                                      [
                                        _vm.isMobileMode
                                          ? _c(
                                              "div",
                                              { class: _vm.mobileHeaderClass },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.headers[2].text)
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "d-flex",
                                            class: _vm.mobileCellClass
                                          },
                                          [
                                            _vm.isNotEmpty(item.attachments)
                                              ? _c(
                                                  "v-menu",
                                                  {
                                                    attrs: {
                                                      "close-on-content-click": true,
                                                      origin: "top left",
                                                      transition:
                                                        "scale-transition",
                                                      absolute: "",
                                                      "offset-overflow": "",
                                                      "offset-y": "",
                                                      right: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "pb-btn",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "attachments-button",
                                                                      attrs: {
                                                                        icon:
                                                                          "",
                                                                        small:
                                                                          "",
                                                                        label: _vm.$t(
                                                                          "attachmentsLabel"
                                                                        ),
                                                                        color:
                                                                          "primary"
                                                                      }
                                                                    },
                                                                    "pb-btn",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "fal fa-paperclip"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _c(
                                                      "v-card",
                                                      _vm._l(
                                                        item.attachments,
                                                        function(
                                                          attachment,
                                                          i
                                                        ) {
                                                          return _c(
                                                            "v-list",
                                                            {
                                                              key:
                                                                "attachment-" +
                                                                item.commentId +
                                                                "-" +
                                                                i
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item",
                                                                [
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    [
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          attrs: {
                                                                            target:
                                                                              "_blank",
                                                                            href:
                                                                              attachment.url
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.getAttachmentName(
                                                                                attachment
                                                                              )
                                                                            ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c("div", {
                                              staticClass:
                                                "comment-text my-auto",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  item.commentText
                                                )
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c("div", {
                                            class: _vm.mobileHeaderClass
                                          })
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { class: _vm.mobileCellClass },
                                        [
                                          _vm.canReply(item)
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "mx-3 hover-button-primary",
                                                  attrs: {
                                                    color:
                                                      _vm.contentBreakpoint ===
                                                      "xs"
                                                        ? "primary"
                                                        : "transparent",
                                                    text: "",
                                                    small: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.addQuickReply(
                                                        item
                                                      )
                                                    },
                                                    keyup: function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      $event.stopPropagation()
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "quickReplyLabel"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _c("span", {
                                                staticClass: "mr-16 ml-3"
                                              }),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "mx-3 hover-button-primary",
                                              attrs: {
                                                color:
                                                  _vm.contentBreakpoint === "xs"
                                                    ? "primary"
                                                    : "transparent",
                                                text: "",
                                                small: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.viewSelectedMessage(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("viewLabel")) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          item.isSelectable
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "mx-3 hover-button-error",
                                                  attrs: {
                                                    color:
                                                      _vm.contentBreakpoint ===
                                                      "xs"
                                                        ? "error"
                                                        : "transparent",
                                                    text: "",
                                                    small: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.deleteSelectedMessage(
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("deleteLabel")
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ])
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.selectedRows,
                      callback: function($$v) {
                        _vm.selectedRows = $$v
                      },
                      expression: "selectedRows"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("pb-confirm", { ref: "messagesConfirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }